
.scroll-fixed-wrap {
    --tab-height: 50px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .v-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 25% !important;
            height: 100%;
        }
    }
    .v-tab {
        &--active {
            color: var(--v-primary-base);
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--border-color);
    }
    &.active {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &:not(.active) {
        .container {
            max-width: var(--container);
            padding: 0;
        }
    }
}
.scrollactive-nav {
    position: relative;
    margin-top: auto;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .scroll-fixed-wrap {
        --tab-height: 60px;
    }
    .scroll-fixed {
        &.active {
            top: 0;
            min-height: 186px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}
